/* Reviews.module.css */
.opinieContainer {
  width: 100%;
  padding: 50px 0;
  background-color: #f8f8f8;
  text-align: center;
  position: relative;;
}

.opinieContent {
  margin: 0 10vw;
  position: relative;;
}

.opinieContent H1 {
  /* text-align: right; */
  font-size: 8vw;
  margin: 0;
}

.hrLine {
  border: none;
  background-color: #000001;
  width: 12vw;
  height: 3px;
  margin-right: 3vw;
  position: relative;
  /* right: 24vw; */
  /* top:0.2vw; */
}

.header {
  display: flex;
  text-align: right;
  justify-content: end;
  align-content: center;
  align-items: center;
}

.opinieRow {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  gap: 3vw;
  position: relative;;
}

.opinia {
  position: relative;
  width: 100%;
  margin: 23vw 8vw 5vw 8vw;
  padding: 1vw 4vw;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.7);
}

.person1 {
  width: 19vw;
   height: 19vw;
   border-radius: 100px;
  margin-bottom: 10px;
  position: absolute;
  top: -10vw;
  left: 23vw;
}

.opinia h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
  margin-top: 12vw;
}

.opinia p {
  font-size: 4vw;
  color: #666;
}

.arrowLeft {
  position: absolute;
  font-size: 1.7rem;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
  top: 70vw;
  left: -7vw;
  background-color: rgba(6, 111, 117, 0.7) ;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.arrowLeft:hover, .arrowRight:hover {
  background-color: rgba(6, 111, 117, 1) ;
}

.arrowRight {
  position: absolute;
  font-size: 1.7rem;
  color: #fff;
  cursor: pointer;
  margin-top: 20px;
  top: 70vw;
  right: -7vw;
  background-color: rgba(6, 111, 117, 0.7) ;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.arrowLeftL, .arrowRightL {
  display: none;
}

.slideIn {
  animation: slideInFromRight 1.7s ease; /* Dostosuj czas i funkcję timingu */
  visibility: visible;
}

.slideIn1 {
  animation: slideInFromLeft 1.7s ease; /* Dostosuj czas i funkcję timingu */
  visibility: visible;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateX(0);
  }

}


@media screen and (min-width: 768px) {

  .opinieContainer {
    width: 100%;
    padding: 50px 0;
    background-color: #f8f8f8;
    text-align: center;
  }
  
  .opinieContent {
    position: relative;
    margin: 0 10vw;
  }
  
  .opinieContent H1 {
    display: flex;
    text-align: right;
    font-size: 2.5rem;
    margin: 0;
   
  }
  
  .hrLine {
    border: none;
    width: 5vw;
    margin-right: 1.5vw;
    /* margin-top: 1.8vw; */
    position:relative;
    right: 0;
    top:0;
    height: 3px;
    background-color: #000001;
    
  }
  
  .opinieRow {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    gap: 3vw;
  }
  
  .opinia {
    position: relative;
    width: 25vw;
    margin: 7vw 1.4vw 2vw 1.4vw;
    padding: 1vw;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.7);
  }
  
  .personImage {
    /* width: 80px;
    height: 80px;
    border-radius: 100px;
    margin-bottom: 10px;
    position: absolute;
    top: -3vw;
    left: 8.5vw; */
  }

  .person1 {
    width: 6vw;
    height: 6vw;
    border-radius: 100px;
    margin-bottom: 10px;
    position: absolute;
    top: -3vw;
    left: 8vw;
  }
  
  .person2 {
    width: 6vw;
    height: 6vw;
    border-radius: 100px;
    margin-bottom: 10px;
    position: absolute;
    top: -3vw;
    left: 8vw;
  }

  .person3 {
    width: 6vw;
    height: 6vw;
      border-radius: 100px;
      margin-bottom: 10px;
      position: absolute;
      top: -3vw;
      left: 8vw;
  }

  .opinia h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
    margin-top: 4vw;
  }
  
  .opinia p {
    font-size: 1rem;
    color: #666;
  }

  .arrowLeft, .arrowRight {
    display: none;
  }
  
  .arrowLeftL {
    position: absolute;
    font-size: 1.7rem;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    top: 23vw;
    left: -6vw;
    background-color: rgba(6, 111, 117, 0.7) ;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  
  .arrowLeft:hover, .arrowRight:hover {
    background-color: rgba(6, 111, 117, 1) ;
  }
  
  .arrowRightL {
    position: absolute;
    font-size: 1.7rem;
    color: #fff;
    cursor: pointer;
    margin-top: 20px;
    top: 23vw;
    right: -6vw;
    background-color: rgba(6, 111, 117, 0.7) ;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .header {
    display: flex;
    text-align: right;
    justify-content: end;
    align-content: center;
  }


}

