.container {
    background-color: white;
    /* padding: 20px; */
    position: relative;
  }
  
  .header {
    color: black;
    padding-left: 17vw;
    font-size: 8vw;
    padding-bottom: 3vw;
  }

  .headerWrapper {
    display: flex;
    justify-items: center;
    align-items: center;
    margin-left: 7vw;
    margin-bottom: 5vw;
  }
  
  .headerWrapper H1 {
    padding: 0 0 0 4vw;

  }

  .imagesContainerAbout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1vw 3vw;
    align-items: center;
  }
  
  .image {
    text-align: center;
  }
  
  .image img {
    width: 60vw;
    /* max-width: 200px; dostosuj szerokość obrazu według potrzeb */
    height: auto;
    margin-bottom: 10px;
  }
  
  .image p {
    color: black;
    width: 60vw;
    padding-bottom: 10vw;
    margin: 0;
    text-align: left;;
  }
  
  .hrLine {
    position: relative;
    /* top: 11.5vw; */
    /* left: 2.1vw; */
    width: 12vw;
    height: 3px;
    background-color: #000001;
    /* margin-left: 5vw; */
    border-style: none;
  }

  .container {
    /* Dodaj pozycję względem widocznego obszaru (viewport) */
    visibility: visible;
  }
  
  .slideIn {
    animation: slideInFromRight 2.2s ease; /* Dostosuj czas i funkcję timingu */
    visibility: visible;
  }
  .slideIn1 {
    animation: slideInFromRight 1.8s ease; /* Dostosuj czas i funkcję timingu */
    visibility: visible;
  }
  .slideIn2 {
    animation: slideInFromRight 1.4s ease; /* Dostosuj czas i funkcję timingu */
    visibility: visible;
  }
  
  @keyframes slideInFromRight {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }



  @media screen and (min-width: 768px) {


    .imagesContainerAbout {
      flex-direction: row;
      align-items: flex-start;
      padding-bottom: 3vw;
    }

    .header {
      font-size: 2.5rem;
      padding-bottom: 3vw;
      padding-left: 10vw;
    }

    .headerWrapper {
      display: flex;
      justify-items: end;
      align-items: center;
      margin: 5vw 2vw 5vw 0;
      text-align: right;
      /* align-items: flex-end; */
      justify-items: flex-end;
      justify-items: end;
      justify-content: end;
    }

    .headerWrapper h1 {
      margin: 0 2vw 0 0;
      padding: 0;
    }

    .hrLine {
      /* top: 3.6vw; */
      /* left: 0; */
      width: 5vw;
      margin: 0 1.5vw;
    }

    .image p {
      padding-bottom: 0;
      width: 23vw;
    }

    .image img {
      width: 23vw;
    }



  }