.footer {
    background-color: #fff;
    color: black;
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100vw;
  }
  
  .leftColumnFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .logoWrapper {
    display: flex;
    margin-bottom: 2vw; 
    justify-content: flex-start;
    margin-top: 1vw;
  }
  
  .textWrapper {
    text-align: center;
  }

  .textWrapper h1 {
    margin-bottom: 5vw;
    font-size: 6.5vw;
    margin-top: 0;
  }

  .textWrapper p {
    margin-bottom: 0vw;
    color: black;
    font-size: 4vw;
  }

  .textWrapper a {
    margin-bottom: 0vw;
    color: black;

  }
  

  .mail {
    padding-top: 4vw;
  }
  
  .rightColumnFooter {
    /* margin-left: 20px; Dodaj margines do oddzielenia kolumn */
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .rightColumnFooter img {
    height: auto;
    width: 95vw;
    /* border-radius: 8px; Zaokrąglenie rogów obrazka */
  }
  
  
  .footerNote {
    font-size: 14px;
  }
  

  .logoImageFooter {
    width: 60vw;
    padding-bottom: 5vw;
    padding-right: 6vw;
    /* padding-top: 4vw; */
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

.logoImageFooter {
  height: auto;
  position: relative;
}


.slideIn {
  animation: slideInFromRight 1.7s ease; 
  visibility: visible; 
}

.slideIn1 {
  /* animation: slideInFromLeft 1.7s ease; 
  visibility: visible; */
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}



  @media screen and (min-width: 768px) {
    
    .footer {
      height: auto;
      align-items: center;
      flex-direction: row;
    }

    .logoImageFooter {
      width: 22vw;
      padding-bottom: 0;
      /* position: absolute; */
      top: 0vw;
    }

    .logoWrapper {
      margin-bottom: 0vw;
      margin-top: 0vw;
      display: flex;
      position: relative;
      top: 0;
      align-items: flex-start;
      align-content: flex-start;
      text-align: left;
    }

    .leftColumnFooter {
      display: flex;
      flex-direction: column;
      align-items: left;
      width: 50%;
      padding-left: 5vw;
    }

    .rightColumnFooter {
      width: 50%;
      margin-right: 5vw;
    }

    .footer {
      background-color: #fff;
      color: black;
      padding: 1vw 1vw 0 1vw;
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .textWrapper h1 {
      margin-top: 4vw;
      margin-bottom: 3vw;
      font-size: 2.6vw;;
      padding-left: 2.2vw;
    }

    .textWrapper p {
      font-size: 1.3vw;
      padding-left: 2.2vw;
    }

    .textWrapper {
      text-align: left;
    }

    .rightColumnFooter img {
      height: auto;
      width: 50vw;
      border-radius: 8px; /* Zaokrąglenie rogów obrazka */
    }

    .mail p{
      padding-top: 0vw;
  }

  .mail {
    padding-left: 2.2vw;
    /* font-size: 1.3vw; */
  }

  }