.App {
  text-align: center;
}

p {
font-family: inter;
}

h1, h2, h3 {
  font-family: roboto;;
}

li {
  font-family: roboto;
}

@font-face {
  font-family: 'Eastman'; /* Dowolna nazwa, którą nadajesz czcionce */
  src: local('YourFontName'), url('../Fonts/EMEN\ Lowercase.ttf') format('truetype'); /* Ścieżka do Twojego pliku czcionki */
  font-weight: normal;
  font-style: normal;
}


@import url('https://fonts.googleapis.com/css2?family=Andada+Pro&family=Hanken+Grotesk:wght@300;500&family=Inter:wght@200;300;400&family=Kalnia:wght@200;400&family=Montserrat:wght@200;300;400;500;600&family=Nunito:wght@300;400;500;600&family=PT+Serif&family=Roboto:wght@100;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Andada+Pro&family=Hanken+Grotesk:wght@300;500&family=Inter:wght@200;300;400&family=Kalnia:wght@200;400&family=Montserrat:wght@200;300;400;500;600&family=PT+Serif&family=Roboto:wght@100;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Andada+Pro&family=Hanken+Grotesk:wght@300;500&family=Inter:wght@200;300;400&family=Kalnia:wght@200;400&family=PT+Serif&family=Roboto:wght@100;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Andada+Pro&family=Hanken+Grotesk:wght@300;500&family=Inter:wght@200;300;400&family=PT+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Andada+Pro&family=Hanken+Grotesk:wght@300;500&family=Inter:wght@200;300;400&family=PT+Serif&family=Roboto:wght@100;300;400;500&display=swap');




