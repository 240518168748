    .aboutUsWrapper {
        background-image: url(./AboutUsPlama2.webp);
        background-repeat: no-repeat;
        background-size: 200%;
        /* background-size: cover; */
        display: flex;
        height: 130vw;
        position: relative;;
      left: -2vw;
      width: 102vw;
      overflow: hidden;
    }
    
    .TitleContainer {
      flex: 1;
      z-index: 10;
      color: #ffffff;
      font-size: 5vw;
      margin-bottom: 10px;
      padding: 7vw 0 0 14vw;
      width: 50%;
    }

    .TitleContainer h1 {
      padding: 0;
      margin: 3vw 15vw;;
    }
    
    .descriptionContainer {
      font-size: 0.9rem;
      color: #ffffff;
    }

    .descriptionContainer {
      padding-right: 5vw;
    }

    .AusImageContainer {
      position: absolute;
      right: 1vw;
      top: 30vw;
    }

    .kasiaWitekImage {
        width: 70vw;
        padding-top: 35vw;
    }

    .dodatek {
      position: absolute;
      width: 15vw;
      color: #000000;
      top: 9vw;
      left: 60vw;
    }

    .hrLine {
      position: absolute;
      top: 10.5vw;
      left: 14.5vw;
      width: 12vw;
      height: 3px;
      background-color: #fff;
      /* margin-left: 5vw; */
      border-style: none;
    }

    /* AboutUs.module.css */

.aboutUsWrapper {
  position: relative;
}

.TitleContainer {
  animation: fadeInUp 1s ease-out;
}

.descriptionContainer {
  animation: fadeInUp 1s ease-out;
}

/* @keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} */
@media screen and (min-width: 400px) {

  .descriptionContainer {
    font-size: 1rem;
    color: #ffffff;
  }

}

@media screen and (min-width: 500px) {

  .descriptionContainer {
    font-size: 1.3rem;
    color: #ffffff;
  }

}

@media screen and (min-width: 600px) {

  .descriptionContainer {
    font-size: 1.5rem;
    color: #ffffff;
  }

}


    @media screen and (min-width: 768px) {

      .aboutUsWrapper {
        background-image: url(./AboutUsPlama2.webp);
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;
        height: auto;
        left: 0vw;
        width: 100%;
        overflow: hidden;
    }

    .TitleContainer h1 {
      padding-left: 2vw;
      margin: 3vw 1.5vw;
      font-size: 2.5rem;
    }

    .AusImageContainer {
      position:relative;
      right: 1vw;
      top: 0;
    }

    .descriptionContainer {
      padding-right: 0;
    }

    .TitleContainer {
      font-size: 2vw;
      margin-bottom: 10px;
      padding: 5vw 0 0 8vw;
      width: 50%;
    }
    
    .descriptionContainer {
      font-size: 1.4vw;
      color: #ffffff;
      line-height: 1.5;
    }

    .kasiaWitekImage {
      width: 50vw;
      /* padding-left: 5vw; */
      padding-top: 9vw;
  }

  .dodatek {
    position: absolute;
    width: 8vw;
    color: #000000;
    top: 5vw;
    left: 21vw;
  }

  .hrLine {
    top: 8.3vw;
    left: 5vw;
    width: 5vw;
    background-color: #fff;
  }


    }
    
