.WebPortfolioWrapper {
  margin: 3vw 0;
  position: relative;
  font-size: 5vw;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}

.header {
  margin-left: 20vw;
  padding-top: 3vw;
}

.laptopContainer {
  position: relative;
  width: 100%;
  margin-top: 6vw;
  display: flex;
  flex-direction: column;
  /* gap: 10vw; */
  align-items: center;
  padding: 0 0 5vw 0;
}

.laptopImage, .MiddleLaptop {
  width: 80%;
  padding: 0 3vw 10vw 3vw;
  transition: transform 0.1s ease-out; /* Dodajemy efekt płynnego przejścia */
}

.screenImage, .MiddleLaptop {
  width: 80%;
  height: 90%;
  transition: transform 0.1s ease-out; /* Dodajemy efekt płynnego przejścia */
}

.hrLine {
  position: absolute;
  top: 9.5vw;
  left: 0;
  width: 12vw;
  height: 3px;
  background-color: #000001;
  margin-left: 5vw;
  border-style: none;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
}

.modalContent {
  position: relative;
  background:none;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  border-radius: 0;
  text-align: center;
}

.modalContainer {
  background: none;
  overflow-y: auto;
  max-height: 80vh; /* Ustaw maksymalną wysokość, aby zmieścić się na ekranie */
}

.modalImage {
  width: 90%;
  border-radius: 20px;
}

.btnModal {
  background-color: #055356;
  color: white;
  padding: 1vw;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 29vw;
  height: 10vw;
  margin-top: 2vw;
  margin-left: 2vw;
}

.btnModal:hover {
  background-color: #033031;
}

.WebPortfolioWrapper a {
  color: white;
}

@media screen and (min-width: 768px) {
  .header {
    margin-left: 11.5vw;
    font-size: 2.5rem;
  }

  .WebPortfolioWrapper {
    height: 40vw;
    display: block;
    font-size: 2.5vw;
    padding-bottom: 0;
    margin: 0;
  }

  .laptopContainer {
    display: block;
    padding: 0;
    gap: 5vw;
  }

  .laptopImage {
    width: 26%;
    padding: 0 3vw;
    cursor: pointer;
  }

  .MiddleLaptop {
    width: 29%;
  }

  .screenImage {
    width: 31%;
    height: 90%;
  }

  .hrLine {
    top: 3.2vw;
    width: 5vw;
  }

  .btnModal {
    width: 12vw;
    height: auto;
  }

  .modalContent {
    width: 70%;
  }

  .laptopImage, .MiddleLaptop {
    padding: 0 3vw;
  }
}
