/* GalleryComponent.module.css */
.masonryGalleryContainer {
    flex-direction: column;
    position: relative;
    padding-bottom: 15vw;
  }

  .showAllButton {
    width: 100%;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }

  .buttonWrapper svg {
    animation: jumpInfinite 1.5s infinite;
    position: absolute;
    margin-left: 37%;
  }


.masonryGalleryContainer {
    display: flex;
    margin: 4vw;
    position: relative;
  }
  
  .masonryGalleryContainer h1 {
    /* padding-left: 7vw; */
    /* font-size: 2rem; */
  }

  .masonryGalleryContainer {
    flex-direction: column;
  }
  
  
  .galleryItem {
    cursor: pointer;
    overflow: hidden;
  }
  
  .galleryItem img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  
  .modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
    background: rgba(255, 255, 255, 1); /* Dodajemy tło do modalu */
    border-radius: 8px;
    padding: 20px;
    overflow: auto;
    z-index: 9999999999;
  }

  .modal {
    position: fixed;
    width: 80%;
    height: 80%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalImage {
    max-width: 100%;
    max-height: 100%;
  }
  
  .modalOverlay {
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
  }
  
  .modalImage {
    max-width: 100%;
    max-height: 80vh; /* Ograniczenie wysokości zdjęcia do 80% wysokości widoku */
  }


.modalContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  
  .buttonWrapper {
    text-align: center;
    position: relative;
    right: 43%;
    bottom: 0vw;
    margin-bottom: 5vw;
  }
  
  
  @keyframes jumpInfinite {
    0% {
      margin-top: 0;
    }
    50% {
      margin-top: 20px;
    }
    100% {
      margin-top: 0;
    }
  }

  .arrowLeft {
    position: absolute;
    left: 2px;
    width: 8vw;
    height: 8vw;
    border-radius: 50px;
    padding: 1vw;
    border-style: none;
    background-color: rgba(6, 111, 117, 1) ;
    font-size: 1vw;
    color: #fff;
    cursor: pointer;
    z-index: 5;
  }
  
  .arrowRight {
    position: absolute;
    right: 2px;
    width: 8vw;
    height: 8vw;
    border-radius: 50px;
    padding: 1vw;
    border-style: none;
    background-color: rgba(6, 111, 117, 1) ;
    font-size: 1vw;
    color: #fff;
    cursor: pointer;
    z-index: 5;
  }

  .arrowLeft,
.arrowRight {
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.5); /* Kolor strzałek z 80% przezroczystości */
  cursor: pointer;
  transition: color 0.3s; /* Dodaj płynne przejścia kolorów */
}

.arrowLeft:hover,
.arrowRight:hover {
  color: rgba(255, 255, 255, 0.7); /* Kolor strzałek w stanie hover z pełną przezroczystością */
}
  
  .closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 42px;
    color: white;
    cursor: pointer;
  }

  .closeButton span {
    margin: 0;
  }
  
  .hrLine {
    position: absolute;
    top: 9.5vw;
    left: 0vw;
    width: 5vw;
    height: 3px;
    background-color: #000001;
    margin-left: 0vw;
    border-style: none;
    display: none;
  }

  @media (min-width: 768px) {




    .masonryGalleryContainer {
        display: flex;
        margin: 4vw;
        position: relative;
        cursor: pointer;
         padding-bottom: 0;
         text-align: left;
      }
      
      .masonryGalleryContainer h1 {
        margin-left: 0;
        margin-bottom: 3vw;
        font-size: 2.7rem;
        margin-right: 0;
        display: flex;
        align-items: flex-start;
        padding-right: 1vw;
      }
      
      .showAllButton {
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        background-color: #007bff;
        color: white;
        border: none;
        cursor: pointer;
      }
      
      .galleryItem {
        cursor: pointer;
        overflow: hidden;
      }
      
      .galleryItem img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }

      .photo:hover {
        transform: scale(1.03);
        max-width: 100%;
        height: auto;
        transition: transform 0.3s ease;
        /* transition: transform 0.5s ease-in-out, border-width 0.5s ease-in-out; */
      }
      
      .modal {
        position: fixed;
        width: 80%;
        height: 80%;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .modalOverlay {
        background: rgba(0, 0, 0, 0.8);
        z-index: 9999;
        width: 90%;
      }
      
      .modalImage {
        max-width: 100%;
        max-height: auto;
      }
      
      .closeButton {
        position: absolute;
        top: 15px;
        right: 30px;
        font-size: 46px;
        color: white;
        cursor: pointer;
        color: #fff;
        cursor: pointer;
      }
      
      .hrLine {
        position:relative;
        top: 0.2vw;
        /* left: 0vw; */
        width: 4.3vw;
        height: 3px;
        background-color: #000001;
        margin-left: 0vw;
        border-style: none;
        display: block;
        margin-right: 1vw;
      }

      .arrowLeft {
        width: 3vw;
        height: 3vw;
        border-radius: 50px;
        padding: 1vw;
        border-style: none;
        background-color: rgba(6, 111, 117, 1) ;
        font-size: 1vw;
        color: #fff;
        cursor: pointer;
        position: absolute;
        left: 80px;
      }
      
      .arrowRight {
        width: 3vw;
        height: 3vw;
        border-radius: 50px;
        padding: 1vw;
        border-style: none;
        background-color: rgba(6, 111, 117, 1) ;
        font-size: 1vw;
        color: #fff;
        cursor: pointer;
        position: absolute;
        right: 80px;
      }

  }
  