
  .ofertaContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    margin: -4vw 0;
    position: relative;
    padding-top: 10vw;
    padding-bottom: 10vw;
  }

  .backgroundText {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    /* margin-top: 13vw; */
  }
  
  .header {
    margin-top: 0;
    z-index: 999;
    position: relative;
    font-size: 8vw;
    margin-left: 5vw;
    padding-left: 15vw;
  }
  
  .column {
    display: flex;
    flex-direction: column;
    /* gap: 10vw; */
    margin: 0 12vw;
    text-align: center;
  }
  
  h3 {
    margin-bottom: 0;
    color: aliceblue;
    font-size: 5.5vw;
    padding: 0 5vw;
  }
  
  .offerBoxPhotography p {
    color: aliceblue;
    font-size: 4vw;
    padding: 0 4vw;
    text-align: left;
  }

  .offerBoxDesign p {
    color: aliceblue;
    font-size: 4vw;
    padding: 0 4vw;
    text-align: left;
  }

  .offerBoxPhotography {
    background-image: url(./1.webp);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 65vw;
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-bottom: 10vw;;
  }

  .offerBoxDesign {
    background-image: url(./2.webp);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 65vw;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  
  .hrLine {
    position: absolute;
    top: 0.3vw;
    left: 0;
    width: 12vw;
    height: 3px;
    background-color: #000001;
    /* margin-left: 5vw; */
    border-style: none;
  }
  

@media screen and (min-width: 768px) {

  .backgroundText {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0;
    overflow: hidden;
    height: 25%; /* Początkowe schowanie do 80% */
    transition: height 0.5s ease; /* Dodaj płynną animację */
  }
  
  .offerBoxPhotography:hover .backgroundText,
  .offerBoxDesign:hover .backgroundText  {
    height: 60%; /* Po najechaniu myszką, pokaż całość */
  }

  .ofertaContainer {
    margin: 0;
    padding-bottom: 5vw;
    padding-top: 0;
  }

  .column {
    flex-direction: row;
    gap: 10vw;
  }

  .header {
    font-size: 2.5rem;
    padding-left: 6.5vw;
  }

  h3 {
    font-size: 1.8vw;
    margin-top: 1.1vw;
    padding: 0 2vw;
  }


  .offerBoxPhotography,
.offerBoxDesign {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 50%;
  height: 25vw;
  justify-content: end;
  margin-bottom: 0;
}

.hiddenParagraph {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 0.7s ease-out, transform 0.7s ease-out;
}

.offerBoxPhotography:hover .hiddenParagraph,
.offerBoxDesign:hover .hiddenParagraph {
  opacity: 1;
  transform: translateY(0);
}

.offerBoxPhotography p {
  color: aliceblue;
  font-size: 1.2vw;
  padding: 0 2vw;
}

.offerBoxDesign p {
  color: aliceblue;
  font-size: 1.2vw;
  padding: 0 2vw;
}

.hrLine {
  position: absolute;
  top: 0.3vw;
  left: 0;
  width: 5vw;
  height: 3px;
  background-color: #000001;
  /* margin-left: 5vw; */
  border-style: none;
}
}