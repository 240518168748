/* Contact.module.css */

.container {
    display: flex;
    background: url('./screen.png') center/cover;
    /* padding: 20px; */
    padding: 5vw 10vw;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  

  .container h1 {
    color: #fff;
    text-shadow: 4px 1px 5px rgba(66, 68, 90, 1);
  }
  
  .leftColumnContact {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  
  .rightColumnContact  {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-right: 5vw;
  }
  
  form {
    width: 100%;
    max-width: 400px; /* Dostosuj szerokość formularza według potrzeb */
  }
  
  label {
    display: none;

  }
  
  input,
  textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: 8px 8px 20px -8px rgba(66, 68, 90, 1);
-moz-box-shadow: 8px 8px 20px -8px rgba(66, 68, 90, 1);
box-shadow: 8px 8px 20px -8px rgba(66, 68, 90, 1);
  }

  .buttonWrapper {
    text-align: end;
  }
  
  .btn {
    background-color: #055356;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 22vw;
    -webkit-box-shadow: 8px 8px 20px -8px rgba(66, 68, 90, 1);
-moz-box-shadow: 8px 8px 20px -8px rgba(66, 68, 90, 1);
box-shadow: 8px 8px 20px -8px rgba(66, 68, 90, 1);
  }
  
  button:hover {
    background-color: #033031;
  }

  .leftColumn br {
    display: none;
  }

  textarea {
    height: 30vw;
    -webkit-box-shadow: 8px 8px 20px -8px rgba(66, 68, 90, 1);
-moz-box-shadow: 8px 8px 20px -8px rgba(66, 68, 90, 1);
box-shadow: 8px 8px 20px -8px rgba(66, 68, 90, 1);
  }

  .ozdobaContact {
   display: none;
  }

  .ozdobaContact2 {
    display: none;
  }

  .slideIn {
/*     animation: slideInFromRight 1.8s ease; /* Dostosuj czas i funkcję timingu */
/*     visibility: visible; */ 
  }

  .slideIn1 {
/*     animation: slideInFromLeft 1.8s ease; /* Dostosuj czas i funkcję timingu */ 
/*     visibility: visible; */
  }

  .sendMail {
      font-size: 5vw;
      font-weight: bold;
  }

  .sendMailWrapper {
    text-align: center;
  } 
  
/*   @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateX(0);
    } */
  


  @media screen and (min-width: 768px) {

    .sendMail {
      font-size: 2vw;
      font-weight: bold;
  }

    .container {
      display: flex;
      background: url('./screen.png') center/cover;
      /* padding: 20px; */
      padding: 5vw 0;
      flex-direction: row;
    }

    .leftColumnContact  br {
      display: block;
    }

    .leftColumnContact  h1 {
      font-size: 2.5rem;
      text-shadow: 4px 1px 5px rgba(66, 68, 90, 1);
      padding-left: 3vw;
    }

    textarea {
      height: 10vw;
      -webkit-box-shadow: 8px 8px 20px -8px rgba(66, 68, 90, 1);
-moz-box-shadow: 8px 8px 20px -8px rgba(66, 68, 90, 1);
box-shadow: 8px 8px 20px -8px rgba(66, 68, 90, 1);
    }

    .leftColumnContact  {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 30%;
    }

    .rightColumnContact  {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 70%;
      padding-right: 10vw;
    }

    .ozdobaContact {
      display: block;
      position: absolute;
      width: 13vw;
      top: -9vw;
      left: 30vw;
    }

    .ozdobaContact2 {
     display: none;
    }

    .buttonWrapper {
      text-align: center;
    }

    .btn {
      width: 10vw;
    }

    label {
      display: none;
    }
    }
  
  
