/* Mission.module.css */

.container {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 3vw 0;
    position: relative;
  }
  
  .leftColumnMission {
    position: relative;
    display: flex;
text-align: center;
/* justify-content: center; */
align-items: center;
  }
  
  .rightColumnMission {
    /* width: 100%; */
    margin-right: 8vw;
  }
  
  .container h1 {
    color: #333;
    padding-left: 4vw;
    font-size: 7vw;
    display: flex;
    align-items: center;
    /* gap: 4vw; */
    padding-bottom: 2vw;
  }
  
  .container p {
    color:#000001;
  }

  .hrLine {
    width: 12vw;
    justify-content: center;
    margin: 0;
    align-items: center;
    align-content: center;
    height: 3px;
    background-color: #000001;
    border-style: none;
    margin-left: 1vw;
  }

  .ozdobaMission {
    position: absolute;
    width: 30vw;
    top: -6vw;
    left: 57vw;
    transform: rotate(0deg);
  }

  .slideIn {
/*     animation: slideInFromRight 1.7s ease;  */
/*     visibility: visible; */
  }
  
  @keyframes slideInFromRight {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1100px) {
  .ozdobaMission {
      display: block;
      position:relative;
      width: 17vw;
      top: 0;
      left: 0;
      padding-left: 0vw;
    } 
  }

  @media screen and (min-width: 768px) {

    .container {
      background-color: white;
      display: flex;
      flex-direction: row;
      padding: 20px;
      margin: 0vw 0;
      justify-content: center;
      justify-items: center;
      align-items: center;
      align-content: center;
    }

    .leftColumnMission {
      width: 55%;
      position: relative;
      display: flex;
text-align: center;
justify-content: center;
align-items: center;
    }

    .rightColumnMission {
      width: 45%;
      margin-right: 8vw;
    }

    .container h1 {
      color: #333;
      /* width: 25vw; */
      /* padding-left: 10vw; */
      font-size: 2.5rem;
      text-align: left;
      /* padding-bottom: 4vw; */
      /* gap: 2vw; */
      padding: 0 0 0 2vw;
      display: inline;
    }

    .hrLine {
      width: 5vw;
      margin-left: 3.8vw;
    }

    .ozdobaMission {
      display: block;
      position:relative;
      width: 17vw;
      top: 0;
      left: 0;
      padding-left: 2vw;
    }

    .container p {
      font-size: 1.4vw;
    }
    



  }
