/* Navigation.module.css */
.navigation {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgb(3, 76, 71);
  background: linear-gradient(
    90deg,
    rgba(3, 76, 71, 1) 0%,
    rgba(2, 81, 97, 1) 46%,
    rgba(6, 111, 117, 1) 68%,
    rgba(3, 96, 114, 1) 94%
  );
  font-size: 18px;
  position: fixed;
  width: 100%;
  margin: 0;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
  /* padding-left: 3vw; */
}


.navBar {
  display: flex;
  flex-direction: row;
  gap: 2.2vw;
}

.logo {
  color: #ffffff;
  /* font-size: 1.5rem; */
  font-weight: bold;
  display: flex ;
}

.logoImage {
  width: 18vw;
  /* padding-right: 28vw; */
}

.navItems {
  /* display: flex; */
  gap: 1.5rem;
  align-items: center;
}

a {
  text-decoration: none;
  color: #ffffff;
}

a:hover {
  opacity: 0.7;
}


.burgerMenu {
  display: none;
}

.fa-home {
  color: #ffffff;
  margin-right: 0.5rem;
}

.navList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.navList li {
  margin: 0;
  padding: 1vw;
  text-decoration: none;
}

li {
  list-style:none;
  font-size: 18px;
  font-family: inter;
  font-weight: bold;
}

a {
  display: inline-block;
  position: relative;
  color: #fff;
}

a::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -0.5vw;
  left: 0;
  background-color: #fff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


@media screen and (max-width: 768px) {

  .navigation {
    padding: 1vw 0;
  }

  .navItems {
    flex-direction: row;
    position: absolute;
    top: 15vw;
    left: 0;
    width: 60%;
    height: 150vw;
    background: rgb(3, 76, 71);
    background: linear-gradient(
      90deg,
      rgba(3, 76, 71, 1) 0%,
      rgba(2, 81, 97, 1) 46%,
      /* rgba(6, 111, 117, 1) 68%, */
      rgba(3, 96, 114, 1) 94%
    );
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    text-align: left; /* Dodaj ten styl, aby wyrównać tekst do lewej po otwarciu menu */
    padding: 5vw 2vw 15vw 2vw;
  }

  .navList {
    flex-direction: column;
  }

  .navItems.showMenu {
    transform: translateX(0);
  }

  .navItems li {
    padding: 1.2vw;
  }

  a:hover {
    opacity: 0.7;
  }

  .burgerMenu {
    display: block;
    cursor: pointer;
    color: #ffffff;
    font-size: 1.5rem;
    margin-right: 1rem;
  }

  .logoImage {
    width: 40vw;
    /* padding-right: 28vw; */
  }

  .navBar {
    display: flex;
    flex-direction: column;
    gap: 2.2vw;
    padding-left: 3vw;
  }


}