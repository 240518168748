/* Top.module.css */

.containerTop {
    background-color: #055356;
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    padding: 12vw 5vw;
  }

  .containerTop p {
    padding: 0 3vw 0 5vw;
  }
  
  .arrowIcon {
    width: 20px; /* Dostosuj szerokość obrazka strzałki według potrzeb */
    height: 20px; /* Dostosuj wysokość obrazka strzałki według potrzeb */
    margin-right: 5px;
  }
  
  p {
    color: white;
  }

  .containerTop {
    /* Dodaj pozycję względem widocznego obszaru (viewport) */
    visibility: visible;
  }
  
  .slideIn {
    animation: slideInFromLeft 1.7s ease-out; /* Dostosuj czas i funkcję timingu */
    visibility: visible;
  }
  
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }



  @media screen and (min-width: 768px) {

    .containerTop {
      background-color: #055356;
      padding: 20px;
      display: grid;
      grid-template-columns: repeat(3, 33.3%);
      gap: 20px;
      padding: 3vw 10vw 3vw 15vw;
    }

    .containerTop p {
      padding: 0;
      font-weight: bold;
      font-size: 1.2rem;
    }


  }
  