.container {
    background-color: #f4f4f4;
    /* padding: 20px; */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 5vw 0;
    position: relative;
    /* padding-bottom: 15vw; */
  }
  
  .feature {
    margin-top: 20px;
    margin-bottom: 10vw;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.7);
    position: relative;;
  }

  .advantagesWrapper {
    display: flex;
    /* gap: 10vw; */
    flex-direction: column;
    margin: 0 15vw;
    text-align: center;
    
  }
  
  .container h1 {
    color: #333;
    /* padding-bottom: 3vw; */
    padding-left: 4vw;
    font-size: 7vw;
    display: flex;
    align-items: center;
    /* gap: 4vw; */
  }
  
  .container h4 {
    color: #333;
  }
  
  .container p {
    color: #555;
  }

  .hrLine {
    position: relative;
    width: 12vw;
    height: 3px;
    background-color: #000001;
    /* margin-left: 5vw; */
    border-style: none;
    /* left: 2vw; */
    /* top: 12vw; */
  }
  
  .headerAdvantages  {
    margin-left: 7vw;
    display: flex;
    text-align: left;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    margin-bottom: 12vw;
  }

  .kropka {
    position: absolute;
    width: 35vw;
    color: #000000;
    top: -19vw;
    left: 15vw;
  }

  .kropka2 {
    position: absolute;
    width: 35vw;
    color: #000000;
    top: -19vw;
    left: 15vw;
  }

  .kropka3 {
    position: absolute;
    width: 35vw;
    color: #000000;
    top: -19vw;
    left: 15vw;
  }


  .slideIn {
    animation: slideInFromRight 1.4s ease; /* Dostosuj czas i funkcję timingu */
    visibility: visible;
  }
  .slideIn1 {
    animation: slideInFromRight 1.8s ease; /* Dostosuj czas i funkcję timingu */
    visibility: visible;
  }
  .slideIn2 {
    animation: slideInFromRight 2.2s ease; /* Dostosuj czas i funkcję timingu */
    visibility: visible;
  }
  
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @media screen and (min-width: 768px) {

    .advantagesWrapper {
      display: flex;
      gap: 8vw;
      justify-content: center;
      flex-direction: row;
      margin: 0vw;
      padding: 3vw 7vw 0 7vw;
    }

    .container {
      padding: 0;
    }


    /* .hrLine {
      position: absolute;
      top: 7.3vw;
      left: 0;
      width: 5vw;
    } */

    .hrLine {
      border: none;
      background-color: #000001;
      width: 5vw;      
      height: 3px;
      margin: 0;
      position: relative;
      margin-right: 2vw;
      left: 0;
      top: 0;
    }
    
    .headerAdvantages {
      display: flex;
      text-align: left;
      justify-content: start;
      align-items: center;
      margin-left: 5.4vw;
      padding-top: 3vw;
      padding-bottom: 3vw;
      margin-bottom: 0;
    }

    .container h1 {
      font-size: 2.5rem;
      /* padding-left: 2vw; */
  
      padding: 0;
      margin: 0;
      
    }

    .feature {
      width: 21%;
      margin-top: 20px;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.7);
    }

    .kropka {
      position: absolute;
      width: 13vw;
      color: #000000;
      top: -7vw;
      left: 4vw;
    }

    .kropka2 {
      position: absolute;
      width: 13vw;
      color: #000000;
      top: -7vw;
      left: 4vw;
    }

    .kropka3 {
      position: absolute;
      width: 13vw;
      color: #000000;
      top: -7vw;
      left: 4vw;
    }

  }
  