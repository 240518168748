/* styles.css */
.cookieModalOverlay {
 position: fixed;
  bottom: 65vw;
  left: 0;
  margin-bottom: 8vw;
  width: 90vw;
  margin: 8vw 5vw 0 5vw;
  height: 20%;
  /* background: #fff; */
  color: rgb(2, 2, 2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  text-align: center;
}

.CookieModal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.CookieModal p {
  color: black;
}

.cookieBtn {

  width: 22vw;
  height: 10vw;
  color: #fff;
  background-color: rgba(3, 76, 71, 1);
  border-radius: 20px;
  border-style: none;
  background: rgba(3, 76, 71, 1) 0%,;
}

.linkToPolityka {
  color: #000; /* Domyślny kolor linku */
  text-decoration: underline; /* Podkreślenie linku */
  cursor: pointer; /* Zmiana kursora na rękę, aby wskazywać, że to interaktywny element */
}

.linkToPolityka:hover {
  color: darkblue; /* Kolor linku po najechaniu myszką */
}

@media screen and (min-width: 768px) {

  .cookieModalOverlay {
    position: fixed;
    bottom: 0;
    margin: 0;
    left: 0;
    width: 100%;
    height: 20%;
    /* background: #fff; */
    color: rgb(2, 2, 2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
  }

  .cookieBtn {

    width: 10vw;
    height: 3vw;
    color: #fff;
    background-color: rgba(3, 76, 71, 1);
    border-radius: 20px;
    border-style: none;
    background: rgba(3, 76, 71, 1) 0%,;
  }




}

