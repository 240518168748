.image {
  position: absolute;
  width: 150%;
  right: 0vw;
  top: 13vw;
  
}

.heroWrapper {
    display: flex;
    overflow: hidden;
    background-color: #ffffff; /* Białe tło */
    padding: 2.4vw;
    color: #000000; 
    padding-top: 10vw;
    margin: 0;
    height: 100vw;
    background: linear-gradient(-45deg, #23A6D5, rgb(3,76,71) ,#119576, rgb(24, 65, 188) 80%);
    background-size: 150%;
    -webkit-animation: Gradient 5s ease infinite;
    -moz-animation: Gradient 5s ease infinite;
    animation: Gradient 5s ease infinite;
  }


  
  @keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .textContainer {
    flex: 1;
    z-index: 10;
    font-size: 3vw;
    margin-bottom: 10px;
    padding-top: 11vw;
  }

  .textContainer h1 {
    width: 100%;
    font-size: 7vw;
  }


  .imageContainer {
    z-index: 11;
    /* overflow: hidden; */
  }
  
  .description {
    font-size: 4vw;
    color:#000000;
    font-family: inter;
  }


  .kasiaWitekImage {
    position: absolute;
    width: 68vw;
    right: 0;
    top: 30vw;
    /* padding-left: 10vw; */
  }

  .dodatek {
    position: absolute;
    width: 30vw;
    color: #000000;
    top: 10vw;
    left: 32vw;
  }


  @media screen and (min-width: 768px) {

    .heroWrapper {
      height: 62vw;
    }

    .image {
      top: -20vw;
      right: 0vw;
    }

    .textContainer {
      font-size: 2.3vw;
      padding-left: 1vw;
      padding-top: 0vw;
    }

    .textContainer h1 {
      margin-bottom: 0;
    }


    .imageContainer {
       position: relative;
      }
    

    .kasiaWitekImage {
      position: absolute;
    right: 0vw;
    top: -4vw;
    object-fit: cover;
    }

    .heroContainer {
      margin: 0 3vw;
    }

    .dodatek {
      position: absolute;
      width: 25vw;
      color: #000000;
      top: 5vw;
      left: 24vw;
    }

    .textContainer h1 {
      width: 100%;
      font-size: 5vw;
    }

    .description {
      font-size: 2vw;
    }

    br p {
      display: none;
    }
  }

